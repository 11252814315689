import React from 'react';
import Helmet from 'react-helmet';
import PageHeader from 'components/PageHeader';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Paragraph } from 'components/Typography';
import styles from './index.module.less';
import image1 from 'images/project-5a.png';
import image2 from 'images/project-5b.png';
import image3 from 'images/project-5c.png';
import image4 from 'images/project-5-before.png';
import image5 from 'images/project-5-after.png';
import Layout from 'components/Layout';

const Page = () => (
  <Layout>
    <Helmet
      title="Dulux Adds some Shine to Taita Home"
      meta={[
        {
          name: 'description',
          content:
            'Friday 19 October was anything but a day in the office for Dulux CEO Jevan Dickinson and an enthusiastic team of sixteen Dulux staff volunteers.'
        }
      ]}>
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Dulux Adds some Shine to Taita Home"
      intro="Friday 19 October was anything but a day in the office for Dulux CEO Jevan and an enthusiastic team of sixteen Dulux staff volunteers."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            Together they rolled up their sleeves and rolled out the topcoats to exterior walls and
            windows, and several of the ceilings inside Trevor and Jane&rsquo;s Taita home.
            Naturally Dulux provided the product.
          </Paragraph>
          <Paragraph>
            The project gave attention to a home which had previously suffered from major damage
            over several years stemming from a rusty leaky roof, and the water damage this led to
            inside. The roof has since been replaced, windows fixed, repairs carried out and all
            made ready for the final attention of the illustrious team of Dulux painters.
          </Paragraph>
          <Paragraph>
            The project was coordinated by Ryhaven Trust, a charity focused on repairing and
            improving homes for low income families who otherwise would not be able to tackle these
            long-overdue kinds of projects. In recent months Ryhaven Trust has been working on homes
            across the greater Wellington region. Families in need of a hand-up partner to assist
            practically where they can alongside Ryhaven professionals, and then repay the Trust in
            affordable repayments.
          </Paragraph>
          <Paragraph>
            Ryhaven Project Manager Bernard Nolan was impressed by this committed Dulux team
            demonstrating a desire to put something back into the community, proud of their product
            and working professionally- and with lots of good cheer.
          </Paragraph>
          <Paragraph>
            &ldquo;They did outstanding work, made a huge difference and were a pleasure to have on
            site&rdquo;. Jane said &ldquo;This house used to feel sad and lonely. Now it has a big
            smile on its face. I couldn&rsquo;t be more proud. We&rsquo;re very grateful to Ryhaven
            and Dulux&rdquo;.
          </Paragraph>
          <img className={styles.image} src={image4} alt="" />
          <img className={styles.image} src={image5} alt="" />
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image3} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Page;
